import 'cookieconsent';
import '../../utilities/globals.js';
import '../../utilities/form.js';
import '../../utilities/stripe-payment-method.js';
import '../../utilities/stripe-payment-intent.js';
import '../../utilities/stripe-action_required.js';
import autosize from 'autosize/dist/autosize.js';
import React from 'react';
import { render } from 'react-dom';
import setAsTagList from '../../utilities/select2.js';
import Flash from '../../utilities/flash.jsx';
import FlashMessages from '../../components/flash';

window.Utilities = window.Utilities || {};
window.Utilities.FMessages = window.Utilities.FMessages || [];

// Without this, the Select2 dropdowns are duplicated when clicking back button
$(document).on('turbo:before-cache', () => {
  $('select').not('.tag_list, .tag_list_select, .pre-completed-program').select2('destroy');
});

$(document).on('turbo:load', () => {
  $('body').addClass('loaded');

  Utility.Globals();
  Prism.highlightAll();

  Utility.StripeActionRequired();
  Utility.StripePaymentIntent();
  Utility.StripePaymentMethod();

  window.flashDiv = render(<FlashMessages messages={Utilities.FMessages} />, $('#flash-messages')[0]);
  Flash.remove();

  $('select').not('.tag_list, .tag_list_select, .pre-completed-program').select2();
  setAsTagList($('select.tag_list'));

  autosize($('textarea'));

  $('.learner-link').on(
    'hover',
    (e) => { $(`.learner-link[data-id='${e.currentTarget.dataset.id}']`).addClass('highlighted'); },
    (e) => { $(`.learner-link[data-id='${e.currentTarget.dataset.id}']`).removeClass('highlighted'); },
  );
});

$(document).on('ajaxComplete, ujs:complete', (event, xhr) => {
  Flash.handleFlashMessagesHeader(window.flashDiv, xhr);
});
