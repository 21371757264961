import 'babel-polyfill';
import Flash from './flash';

window.Utility = window.Utility || {};

Utility.StripeActionRequired = () => {
  if ($('a.subscription_action_required_link').length) {
    const stripe = Stripe($("meta[name='stripe-key']").attr('content'));

    const handleError = (error, target) => {
      Flash.setMessage(window.flashDiv, 'error', error.message);
      setTimeout(() => {
        $(target).removeAttr('disabled');
      }, 500);
    };

    $('a.subscription_action_required_link').on('click', (e) => {
      e.preventDefault();
      $(e.target).attr('disabled', 'true');
      const { clientSecret, paymentMethod } = e.target.dataset;
      let data;
      if (paymentMethod) {
        data = {
          payment_method: paymentMethod,
          setup_future_usage: 'off_session',
        };
      }
      stripe.confirmCardPayment(clientSecret, data).then((result) => {
        if (result.error) {
          handleError(result.error, e.target);
        } else {
          Flash.setMessage(window.flashDiv, 'notice', 'Confirmation successful. Processing payment…');
        }
      });
    });
  }
};
