import 'select2';

function setAsTagList(elements) {
  $(elements).select2({
    tags: true,
    tokenSeparators: [',', ' '],
    placeholder: 'Add tags',
    width: 'resolve',
    ajax: {
      delay: 100,
      url() {
        return this[0].dataset.url;
      },
      data(params) {
        const query = {
          term: params.term,
        };
        return query;
      },
      processResults(response) {
        let newData = [];
        if (response.data.length > 0) {
          newData = response.data.map((tag) => ({ id: tag.attributes.name, text: tag.attributes.name }));
        }
        return {
          results: newData,
        };
      },
    },
  });
}

export default setAsTagList;
