import 'babel-polyfill';

window.Utility = window.Utility || {};

Utility.StripePaymentMethod = () => {
  if ($('#stripe-payment-method').length) {
    const stripe = Stripe($("meta[name='stripe-key']").attr('content'));
    const clientSecret = $('#stripe-payment-method').data('clientSecret');
    const lang = $('html').attr('lang').substring(0, 2);
    const elements = stripe.elements({ locale: lang });

    // Create an instance of the card Element
    const cardElement = elements.create('card');

    // Add an instance of the card Element into the 'stripe-source' div
    cardElement.mount('#card-element');

    cardElement.addEventListener('change', ({ error }) => {
      const displayError = document.getElementById('card-errors');
      if (error) {
        displayError.textContent = error.message;
        displayError.style.display = 'block';
      } else {
        displayError.textContent = '';
        displayError.style.display = 'none';
      }
    });

    const handleError = (error, target) => {
      // Inform the user if there was an error
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
      errorElement.style.display = 'block';
      setTimeout(() => {
        $(target).find('input[type=submit]').removeAttr('disabled');
      }, 500);
    };

    const handleIntent = (setupIntent) => {
      // Insert the token ID into the form so it gets submitted to the server
      const $form = $('#stripe-payment-method');
      $form.append($('<input type="hidden" name="stripe_card[stripe_id]" />').val(setupIntent.payment_method));
      $form.submit();
    };

    // Create a token or display an error the form is submitted.
    const form = document.getElementById('stripe-payment-method');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: { name: form.querySelector('input[name=cardholder-name]').value },
          },
        },
      ).then((result) => {
        if (result.error) {
          handleError(result.error, event.target);
        } else {
          handleIntent(result.setupIntent);
        }
      });
    });
  }
};
