import 'babel-polyfill';

window.Utility = window.Utility || {};

Utility.StripePaymentIntent = () => {
  if ($('#stripe-payment-intent-form').length) {
    const stripe = Stripe($("meta[name='stripe-key']").attr('content'));
    const clientSecret = $('#stripe-payment-intent-form').data('clientSecret');
    const paymentMethod = $('#stripe-payment-intent-form').data('paymentMethod');

    const handleError = (error, target) => {
      // Inform the user if there was an error
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
      errorElement.style.display = 'block';
      setTimeout(() => {
        $(target).find('input[type=submit]').removeAttr('disabled');
      }, 500);
    };

    const handleIntent = (setupIntent) => {
      // Insert the token ID into the form so it gets submitted to the server
      const $form = $('#stripe-payment-intent-form');
      $form.append($('<input type="hidden" name="stripe_card[stripe_id]" />').val(setupIntent.payment_method));
      $form.submit();
    };

    // Create a token or display an error the form is submitted.
    const form = document.getElementById('stripe-payment-intent-form');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      stripe.confirmCardSetup(clientSecret, { payment_method: paymentMethod }).then((result) => {
        if (result.error) {
          handleError(result.error, event.target);
        } else {
          handleIntent(result.setupIntent);
        }
      });
    });
  }
};
